<template>
  <div class="newVoteCourItem_container global_vote_font">
    <div class="newVoteCourItem_doctorInfo" :class="getbgColor()">
      <div class="newVoteCourItem_doctorInfo_num"></div>
      <div class="avater_content">
        <img
          :src="item.profile"
          class="newVoteCourItem_avater"
          :class="getborderColor()"
        />
        <img
          v-if="item.rank == 1 && item.vote_count !== 0 && !isVote"
          :src="
            !role
              ? require('@/assets/images/challenge/trophy.png')
              : require('@/assets/images/challenge/trophy-1.png')
          "
          class="newVoteCourItem_trophy"
        />
      </div>
      <div class="newVoteCourItem_Info">
        <p>{{ item.doctor_hospital }} {{ item.doctor_name }}医生</p>
        <div class="newVoteCourItem_Info_courname">
          {{ item.courseware_name }}
        </div>
      </div>
      <!-- 图标 -->
      <div class="newVoteCourItem_attention">
        <div class="newVoteCourItem_attention_block">
          <div class="newVoteCourItem_attention_img">
            <img
              ref="imgRef"
              :src="require('@/assets/images/challenge/total-votes-1.png')"
              class="icon_big"
            />
          </div>
          <div>{{ item.vote_count }}</div>
        </div>
        <div class="newVoteCourItem_attention_block">
          <!-- 大众 -->
          <div class="newVoteCourItem_attention_img">
            <img ref="imgRef" :src="getImage()" class="icon_small" />
          </div>
          <div>{{ item.vote_count_user }}</div>
        </div>
        <div class="newVoteCourItem_attention_block">
          <!-- 专家 -->
          <div class="newVoteCourItem_attention_img">
            <img ref="imgRef" :src="getProImage()" class="icon_small" />
          </div>
          <div>{{ item.vote_count_doctor }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hidden: "hidden",
    };
  },
  props: {
    item: Object,
    index: Number,
    role: Number,
    isVote: Boolean,
    activeType: Boolean,
    mode: [Boolean, Number],
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.item.vote_count = this.item.vote_count ? this.item.vote_count : 0;
    },
    //大众
    getImage() {
      let name;
      if (this.mode) {
        name = "public-votes-1";
      } else {
        name =
          this.item.voteForMeCount && !this.role
            ? "public-votes-1"
            : "total-votes";
      }
      return require(`@/assets/images/challenge/${name}.png`);
    },
    //专家
    getProImage() {
      let name;
      if (this.mode) {
        name = "proficient-votes-1";
      } else {
        name =
          this.item.voteForMeCount && this.role
            ? "proficient-votes-1"
            : "total-votes";
      }

      return require(`@/assets/images/challenge/${name}.png`);
    },
    getbgColor() {
      if (!this.mode) {
        if (this.index % 2 == 0) return "newVoteCourItem_doctorInfo_active";
        return;
      } else {
        return this.index % 2 !== 0 && !this.role
          ? "newVoteCourItem_doctorInfo_active"
          : this.index % 2 !== 0 && this.role
          ? "newVoteCourItem_doctorInfo_active_new"
          : "";
      }
    },
    getborderColor() {
      if (!this.mode) return "newVoteCourItem_avater";
      else if (this.role) return "newVoteCourItem_avater_new";
      else return "newVoteCourItem_avater";
    },
  },
};
</script>

<style >
.newVoteCourItem_container {
  width: 100%;
  padding-right: 5.33vw;
  background: #f2f2f3;
  font-size: 3.2vw;
  border-radius: 2vw;
  position: relative;
}
.newVoteCourItem_doctorInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.newVoteCourItem_doctorInfo_num {
  margin: 0 2.67vw 0 5.33vw;
}
.newVoteCourItem_doctorInfo_num_hidden {
  visibility: hidden;
}
.avater_content {
  position: relative;
  margin: 1.33vw 0 1.33vw 1vw;
}
.newVoteCourItem_avater {
  width: 16vw;
  height: 16vw;
  margin-right: 2.67vw;
  border-radius: 50%;
  /* border: 1px solid #9e004c; */
  background: #fff !important;
}

.newVoteCourItem_trophy {
  width: 4.67vw;
  height: 4.67vw;
  position: absolute;
  top: 0;
  left: 0;
}
.newVoteCourItem_Info {
  height: 16vw;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 2.6vw;
}
.newVoteCourItem_Info p {
  font-size: 3.73vw;
  font-weight: bold;
}
.newVoteCourItem_doctorInfo_active {
  background-image: linear-gradient(to right, #eadce4, rgb(242, 242, 243));
}

.newVoteCourItem_doctorInfo_active_new {
  background-image: linear-gradient(to right, #e4ebc9, rgb(242, 242, 243));
}
.newVoteCourItem_attention {
  width: 12vw;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: #8d8e8d;
  margin-left: 2.67vw;
}
.newVoteCourItem_attention_block {
  width: 100%;
  display: flex;
  /* justify-content: space-between; */
  /* margin: 0.5vw 0; */
  align-items: center;
}
.newVoteCourItem_attention_img {
  width: 5vw;
  height: 5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2vw;
}
.newVoteCourItem_Info_courname {
  height: 8vw;
  line-height: 3.9vw;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
.icon_small {
  width: 4vw;
  height: 4vw;
}
.icon_big {
  width: 5vw;
  height: 5vw;
}
.newVoteCourItem_avater {
  width: 16vw;
  height: 16vw;
  margin-right: 2.67vw;
  border-radius: 50%;
  border: 1px solid #9e004c;
  background: #fff !important;
}
.newVoteCourItem_avater_new {
  width: 16vw;
  height: 16vw;
  margin-right: 2.67vw;
  border-radius: 50%;
  border: 1px solid #b7d635;
  background: #fff !important;
}
</style>